import React from "react"
import styles from "./task.module.css"

export const Task = ({ image, title, description }) => {
  return (
    <div className={styles.block}>
      <img className={styles.image} src={image} alt={"card"} />
      <div className={styles.boxContainer}>
        <h1 className={styles.box_title}>{title}</h1>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  )
}