// import { dataURItoBLOB } from "./dataURItoBLOB"

import { delay } from "q"

export interface IResponse {
  status: boolean
  message: string
}

interface IRequest {
  name: string,
  contact: string,
  message?: string
  surname?: string
}

export const sendResume = async ({
                                   name,
                                   surname,
                                   contact,
                                   message,
                                 }: IRequest,
): Promise<IResponse> => {
  try {
    const body = new FormData()
    body.append("name", name)
    body.append("surname", surname)
    body.append("contact", contact)
    body.append("message", message)

    async function delay(delayInms) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(2)
        }, delayInms)
      })
    }

    async function sample() {
      console.log("a")
      console.log("waiting...")
      let delayres = await delay(3000)
      console.log("b")
    }

    sample()

    // await fetch("https://dex-it.ru/Ru/Page/TechTaskEmail", {
    //   method: "POST",
    //   headers: {
    //     // "Content-Type": "multipart/form-data",
    //   },
    //   body,
    // })

    return {
      status: true,
      message: "Спасибо! Мы ответим Вам в течение 45 мин!",
    }
  } catch (error) {
    return { status: false, message: error.message }
  }
}