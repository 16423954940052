import React from "react"
import globalStyle from "../global.module.css"
import styles from "./forum.module.css"
import forum from "../../images/forum.svg"
import classnames from "classnames"

export const Forum = () => {
  return (
    <div className={styles.background}>
      <a className={globalStyle.anchor} id="projects" />
      <div className={styles.container}>
        <div
          className={classnames(globalStyle.globalContainer, styles.wrapper)}
        >
          <div className={styles.block}>
            <div className={styles.leftcontainer}>
              <h1 className={classnames(globalStyle.globalTitle, styles.title)}>
                Профориентационный <br /> форум
              </h1>
              <div className={styles.imageContainerMobile}>
                <img src={forum} alt={"forum"} />
              </div>
              <div>
                <p className={styles.text}>
                  14 декабря, во Дворце Республики прошел самый масштабный форум
                  по профориентации молодежи.
                  <br />
                  Ребята 8-11 классов задавали много вопросов спикерам, а также
                  узнали для себя много нового и интересного, что поможет им в
                  будущем легче определиться с профессией своей жизни.
                </p>
              </div>
              <div className={classnames(styles.block, styles.peopleContainer)}>
                <div>
                  <p className={styles.number}>500 +</p>
                  <p className={styles.people}>участников</p>
                </div>
                <div>
                  <p className={styles.number}>10 +</p>
                  <p className={styles.people}>спикеров</p>
                </div>
              </div>
            </div>
            <div className={styles.imageContainer}>
              <img src={forum} alt={"forum"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
