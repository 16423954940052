import React, {useState} from "react"
import styles from "./advanced.module.css"
import globalStyle from "../global.module.css"
import classnames from "classnames"
import { Modal } from "../modal/Modal"

export const Advanced = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(!show)
  }
  const hideModal = () => {
    setShow(false)
  }
  return (
    <>
      {show &&
      <Modal hideModal={hideModal}>
        <div>
          <div>title</div>
          TEXT MODAL
        </div>
      </Modal>
      }
      <div className={styles.container}>
        <div
          className={classnames(globalStyle.globalContainer, styles.wrapper)}
        >
          <p className={styles.text}>Хочу узнать подробности!</p>
          <div className={styles.inputContainer}>
            <input className={styles.input} placeholder={"Имя"} />
            <input className={styles.input} placeholder={"Телефон"} />
            <span className={styles.btnContainer}>
              <button onClick={handleClick} className={styles.btn}>Отправить</button>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
