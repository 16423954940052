import React from "react"
import styles from "./footer.module.css"
import footerimg from "../../images/Footer.svg"
import globalStyle from "../global.module.css"
import classnames from "classnames"
import { Facebook, Vk, Telegram, Instagram } from "./social"

export const Footer = () => {
  return (
    <div className={styles.container}>
      <a className={globalStyle.anchor} id="contacts" />
      <div className={classnames(globalStyle.globalContainer, styles.wrapper)}>
        <div className={styles.block}>
          <div className={styles.imgContainer}>
            <img src={footerimg} alt={"footer"} />
          </div>
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>Фонд развития ИТ Приднестровья</h1>
            <div className={styles.phoneContainer}>
              <a className={styles.phone} href="tel:053376843">
                0 (533) 768-43;
              </a>
              <a className={styles.phone} href="tel:077778355">
                0 (777) 783-35
              </a>
            </div>
            <a className={styles.phone} href="https://stage.fbit.md/#projects">
              Bit-PMR.ru
            </a>
          </div>
          <div className={styles.imgContainerSmall}>
            <img src={footerimg} alt={"footer"} />
          </div>
        </div>
        <div className={styles.links}>
          <Facebook
            styles={styles.social}
            url={"https://www.facebook.com/fondbit"}
          />
          <Vk styles={styles.social} url={"https://www.facebook.com/fondbit"} />
          <Instagram
            styles={styles.social}
            url={"https://www.facebook.com/fondbit"}
          />
          <Telegram
            styles={styles.social}
            url={"https://www.facebook.com/fondbit"}
          />
          <a href={`#aim`} className={styles.link1}>
            Цель
          </a>
          <a href={`#tasks`} className={styles.link2}>
            Задачи
          </a>
          <a href={`#projects`} className={styles.link3}>
            Проекты
          </a>
          <a href={`#organizers`} className={styles.link4}>
            Организаторы
          </a>
          <a href={`#finance`} className={styles.link5}>
            Финансы
          </a>
          <a href={`#contacts`} className={styles.link7}>
            Контакты
          </a>
        </div>
      </div>
    </div>
  )
}
