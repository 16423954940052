import React, { useState } from "react"
import styles from "./form.module.css"
import globalStyle from "../global.module.css"
import { Input } from "./Input"
import classnames from "classnames"
import pdfImage from "../../images/3к2.svg"
import pdf from "../resource/ReactCourse.pdf"
import { v1 } from "uuid"
import { Spinner } from "../spinner/Spinner"

import { sendResume } from "../../components/services/SendResume"
import { Error } from "../error/error"
import { CustomModal } from "../modal/Modal"

export const Form = () => {
  const [load, setLoad] = useState(false)
  const [key, setKey] = useState(v1().toString())

  const [name, setName] = useState("")
  const [nameError, setNameError] = useState()

  const [contact, setContact] = useState("")
  const [contactError, setContactError] = useState()

  const [surname, setSurname] = useState("")
  const [message, setMessage] = useState("")

  const [show, setShow] = useState(false)
  const hideModal = () => {
    setShow(false)
  }

  const onSubmit = async () => {
    const nameCurentError = name.trim() === ""
    setNameError(nameCurentError)
    const contactCurentError = contact.trim() === ""
    setContactError(contactCurentError)

    console.log(contactError)

    if (!nameCurentError && !contactCurentError) {
      // setFailMessage(result.message)
      setLoad(true)
      const result = await sendResume({ name, surname, contact, message })

      setShow(!show)

      setName("")
      setSurname("")
      setContact("")
      setMessage("")
      setKey(v1().toString())
      setLoad(false)
    } else {
      setLoad(false)
      // setSuspenseMassage(result.message)
    }
  }

  return (
    <>
      {show && 
        <CustomModal
          isOpen={show}
          hideModal={hideModal}
          title={"Сообщение успешно отправлено"}
          text={"Мы ответим Вам в течение 45 мин!"}
        />
      }
      <div key={key} className={styles.background}>
        <a className={globalStyle.anchor} id="contactform" />
        <div className={styles.container}>
          <div
            className={classnames(globalStyle.globalContainer, styles.wrapper)}
          >
            <h1 className={classnames(styles.title, globalStyle.globalTitle)}>
              Форма связи
            </h1>
            <p className={styles.text}>
              Напишите нам, если Вы разделяете цели и задачи фонда развития
              образования Приднестровья и готовы оказать помощь любого рода
            </p>
            <div className={styles.form}>
              <div className={styles.inputContainer}>
                <div className={styles.inputErrorContainer}>
                  <Input onChangeField={setName} placeholder={"Имя"} />
                  <Error isActive={nameError}>
                    Пожалуйста, введите Ваше имя
                  </Error>
                </div>
                <div className={styles.inputErrorContainer}>
                  <Input onChangeField={setSurname} placeholder={"Фамилия"} />
                </div>
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.inputErrorContainer}>
                  <Input
                    onChangeField={setContact}
                    placeholder={"Ваш контакт"}
                  />
                  <Error isActive={contactError}>
                    Пожалуйста, заполните поле
                  </Error>
                </div>
                <div className={styles.inputErrorContainer}>
                  <Input
                    onChangeField={setMessage}
                    placeholder={"Какого рода помощь?"}
                  />
                  <Error isActive={contactError}>
                    Пожалуйста, заполните поле
                  </Error>
                </div>
              </div>

              {!load ? (
                <button onClick={onSubmit} className={styles.btn}>
                  Отправить
                </button>
              ) : (
                <span style={{display: "flex"}}>
                  <button onClick={onSubmit} className={styles.btn}>
                    {/*<Spinner />*/}
                    Отправить
                  </button>
                </span>
              )}
            </div>
            <div className={styles.container_help}>
              <div className={styles.helps}>
                <div className={styles.helpWrapper}>
                  <h1 className={styles.help_description}>
                    Для оказания прямой материальной помощи
                  </h1>
                  <a
                    target="_blank"
                    href={pdf}
                    className={classnames(
                      styles.containerImage,
                      globalStyle.decorationLink
                    )}
                  >
                    <div className={styles.download}>
                      <img
                        className={styles.imgMin}
                        src={pdfImage}
                        alt={"pdfImage"}
                      />
                      <p className={styles.downloadText}> СКАЧАТЬ</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
