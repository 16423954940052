import React from "react"
import styles from "./header.module.css"
import scrollTo from "gatsby-plugin-smoothscroll"

export const Item = ({href, text}) => {
  return (
    <li className={styles.linkContainer} onClick={() => scrollTo(href)} >
      <a className={styles.link}>
        {text}
      </a>
    </li>
  )
}
