import React from "react"
import add1 from "../../images/add1.svg"
import add2 from "../../images/add4.svg"
import add5 from "../../images/add5.svg"

import styles from "./task.module.css"
import globalStyle from "../global.module.css"
import classnames from "classnames"
import { Task } from "./task"

export const Tasks = () => (
  <div className={styles.background}>
  <a className={globalStyle.anchor} id="tasks" />
    <div className={styles.container}>
      <div
        className={classnames(
          globalStyle.globalContainer,
          styles.wrappContainer
        )}
      >
        <h1 className={classnames(globalStyle.globalTitle, styles.wrapper)}>
          Поставленные задачи
        </h1>
        <div className={styles.card}>
          <div className={classnames(styles.globalBlock, styles.cardContainer)}>
            <h1 className={styles.mobileTasks}>
              Поставленные задачи
            </h1>
            <Task
              title={"Кадры"}
              image={add1}
              description={"Помощь учителям (финансовая\n и методическая)"}
            />
            <Task
              title={"Программа"}
              image={add2}
              description={"Актуализация программы\n школьного образования"}
            />
          </div>
          <div className={styles.infrastructure}>
            <Task
              title={"Инфраструктура"}
              image={add5}
              description={
                "Улучшение материально-технической базы, организация\n дополнительных курсов, факультативов проведение ИТ\n мероприятий (конференций, митапов, олимпиад)"
              }
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)
