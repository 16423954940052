import React, { useState } from "react"
import { Link } from "gatsby"
import logo from "../../images/bit-logo.svg"
import styles from "./header.module.css"
import globalStyle from "../global.module.css"
import classnames from "classnames"
import OutsideClickHandler from "react-outside-click-handler"
import {Item} from "./Item"

export const Header = () => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <header className={styles.headerContainer}>
      <OutsideClickHandler onOutsideClick={handleClose}>
        <div className={styles.container}>
          <div
            className={classnames(globalStyle.globalContainer, styles.header)}
          >
            <Link className={styles.linkTop} to="/">
              <img className={styles.logo} src={logo} alt={"logo"} />
            </Link>
            <label className={styles.menuIcon} htmlFor="menu-btn">
              <span className={styles.navicon} />
            </label>
            <ul
              className={classnames(styles.menu, { [styles.open]: open })}
              onClick={handleClose}
            >
              <Item href={"#aim"} text={"Цель"} />
              <Item href={"#tasks"} text={"Задачи"} />
              <Item href={"#projects"} text={"Проекты"} />
              <Item href={"#organizers"} text={"Организаторы"} />
              <Item href={"#finance"} text={"Финансы"} />
              <Item href={"#contacts"} text={"Контакты"} />
            </ul>
              <div className={classnames(styles.menuBtn, open ? styles.open : null)} onClick={handleClick} >
                <span/>
                <span/>
                <span/>
                <span/>
              </div>
          </div>
        </div>
      </OutsideClickHandler>
    </header>
  )
}
