import React from "react"
import Modal from "react-modal"

import styles from "./modal.module.css"

const customStyles = {
  content : {
    width: '70%',
    padding: '47px',
    left: '15%',
    top: '37%',
    bottom: 'unset',
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000
  }
};

export const CustomModal = (props) => {
  return (
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.hideModal}
        style={customStyles}
        contentLabel="Modal"
        appElement={document.body}
      >
        <div className={styles.modal}>
          <div className={styles.title}>
            {props.title}
            <div className={styles.text}>{props.text}</div>
          </div>
        </div>
      </Modal>
  )
}
