/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Header } from "./header/header"
import { Target } from "./target/target"
import { Tasks } from "./tasks/tasks"
import "./layout.css"
import { Forum } from "./forum/Forum"
import { Advanced } from "./advanced/Advanced"
import { Organizatior } from "./organizator/Organizators"
import { Form } from "./form/Form"
import { Footer } from "./footer/footer"

export const Layout = ({ children }) => {
  return (
    <div>
      <Header/>
      <main>
        {children}
        <Target/>
        <Tasks/>
        <Forum/>
        <Advanced/>
        <Organizatior/>
        <Form/>
      </main>
      <footer>
        <Footer/>
      </footer>
      <div id="top"/>
      {/* <div id="pashalka"/> */}
      <div id="bottom"/>
    </div>
  )
}
