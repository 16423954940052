// import React from "react"
import styles from "../form/form.module.css"
import classnames from "classnames"
import React, { FC, useState, useEffect, memo } from "react"

// interface IProps {
  // label: string;
  // onChange: (e: string) => void;
  // style?: string;
  // styleLabel?: string;
// }


// export const Input = ({ placeholder }) => {
//   return <input className={styles.input} placeholder={placeholder} />
//
// }

export const Input = ({ placeholder, onChangeField, style }) => {
  return (
        <input
          onChange={(e) => onChangeField(e.target.value)}
          type="text"
          placeholder={placeholder}
          className={classnames(styles.input, style)}
          required
        />
  )
}