import React from "react"
import styles from "./target.module.css"
import globalStyle from "../global.module.css"
import { Aim } from "./Aim"
import classnames from "classnames"

export const Target = () => (
  <div className={styles.container}>
  <a className={globalStyle.anchor} id="aim"/>
    <div className={classnames(globalStyle.globalContainer, globalStyle.globalTitle, styles.wrapper)}>
      <h1 className={styles.title}>Наша цель</h1>
      <p className={styles.description}>
        Вывести ИТ образование в Приднестровье на новый уровень
      </p>
      <div className={classnames(styles.blocks, styles.blocksMobile)}>
        <Aim title={"Школы"} text={"Школа школа я скучаю я скучаю. Ля ля"} />
        <Aim
          title={"Университет"}
          text={"Привет универ, я не скучал вовсе. 6 прошло и норм"}
        />
        <Aim
          title={"Доп.образование"}
          text={"Факультативы, курсы, кружки и др.обучающие мероприятия"}
        />
      </div>
    </div>
  </div>
)
