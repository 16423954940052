import React from "react"
import styles from "./target.module.css"

export const Aim = ({ title, text }) => {
  return (
    <div className={styles.blocksAim}>
      <div className={styles.block}>
        <div>
          <h1 className={styles.block_title}>{title}</h1>
          <p className={styles.block_description}>{text}</p>
        </div>
      </div>
    </div>
  )
}
