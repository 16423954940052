import React from "react"
import styles from "./organizator.module.css"
import globalStyle from "../global.module.css"
import classnames from "classnames"
import organizers1 from "../../images/organizers1.svg"
import organizers2 from "../../images/organizers2.svg"

export const Organizatior = () => {
  return (
    <div className={classnames(styles.background)}>
      <a className={globalStyle.anchor} id="organizers" />
      <div className={styles.container}>
        <div
          className={classnames(globalStyle.globalContainer, styles.wrapper)}
        >
          <h1 className={classnames(globalStyle.globalTitle, styles.title)}>
            Организаторы и партнеры
          </h1>
          <div className={styles.organizersWrapper}>
            <div className={styles.organizersRow}>
              <div className={styles.organizersColumn}>
                <img src={organizers1} alt="#" />
              </div>
              <div className={styles.organizersColumn}>
                <img src={organizers1} alt="#" />
              </div>
              <div className={styles.organizersColumn}>
                <img src={organizers1} alt="#" />
              </div>
              <div className={styles.organizersColumn}>
                <img src={organizers1} alt="#" />
              </div>
            </div>
            <div className={styles.organizersRow}>
              <div className={styles.organizersColumn}>
                <img src={organizers2} alt="#" />
              </div>
              <div className={styles.organizersColumn}>
                <img src={organizers2} alt="#" />
              </div>
              <div className={styles.organizersColumn}>
                <img src={organizers2} alt="#" />
              </div>
              <div className={styles.organizersColumn}>
                <img src={organizers2} alt="#" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
